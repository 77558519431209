import React from "react";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";

import LineChart from "./line-chart";
import ShowForRole from "../../../components/Permission/ShowForRole";
import { UserRole } from "../../../constants/user/userRole";

const ServersGrid = ({ servers = {}, methods }) => {
  return (
    <Row>
      {servers?.hosts?.map((server) => (
        <Col key={server.id} md={12} className="d-nonee">
          <Card>
            <CardHeader className="d-flex justify-content-between">
              <h4 className="card-title mb-0">{server.name}</h4>
              <div>  
                <Button
                  size="sm"
                  color="primary"
                  className="me-2"
                  onClick={() => methods.onShowServerLogs(server)}
                >
                  <i className="fas fa-book"></i> Mostrar logs
                </Button>
              
                {server?.can_be_forced_to_restart ? (
                  <Button
                    size="sm"
                    color="danger"
                    className="me-2"
                    onClick={() => methods.onForceResetServer(server)}
                  >
                    <i className="fas fa-redo"></i> Forzar reinicio
                  </Button>
                ) : null}
                {server?.can_be_reset ? (
                  <Button
                    size="sm"
                    color="primary"
                    onClick={() => methods.onResetServer(server)}
                  >
                    <i className="fas fa-sync-alt"></i> Reiniciar
                  </Button>
                ) : null}
              </div>
            </CardHeader>
            <CardBody>
              <Row>
                {server?.checks?.map((check) => (
                  <Col md={6} className="pb-4" key={check.title}>
                    <LineChart
                      yMax={check?.yMax}
                      title={check.title}
                      unit={servers?.unit}
                      xMin={servers?.xMin}
                      xMax={servers?.xMax}
                      yTitle={check.yTitle}
                      datasets={check?.datasets}
                      stepSize={servers.stepSize}
                    />
                  </Col>
                ))}
              </Row>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default ServersGrid;
